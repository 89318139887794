import "../App.css";
import "primereact/resources/themes/bootstrap4-dark-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import CustomSidebar from "./Sidebar";
import Applications from "./Applications";
import Users from "./Users";
import ErrorModal from "./ErrorModal";
import GuacamoleConnection from "./GuacamoleConnection";
import ApplicationService from "../services/ApplicationService";
import UserService from "../services/UserService";

const loginRequest = {
  scopes: ["User.Read"],
};

const App = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [visible, setVisible] = useState(false);
  const [applications, setApplications] = useState([]);
  const [activeApps, setActiveApps] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentView, setCurrentView] = useState("applications");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [guacAuthStatus, setGuacAuthStatus] = useState(false);
  const [launchInNewTab, setLaunchInNewTab] = useState(false); // State for the checkbox

  let userAccount = accounts[0];

  useEffect(() => {
    if (isAuthenticated) {
      console.log("The user successfully authenticated");

      const request = {
        ...loginRequest,
        account: userAccount,
      };

      instance
        .acquireTokenSilent(request)
        .then((response) => {
          console.log("Access token retrieved silently:", response.accessToken);
          sessionStorage.setItem("authToken", response.accessToken);
          console.log(
            "Token stored in sessionStorage:",
            sessionStorage.getItem("authToken")
          );

          loadData(); // Load data immediately after getting the token
        })
        .catch((error) => {
          console.error("Failed to acquire token silently:", error);
          instance
            .acquireTokenPopup(request)
            .then((response) => {
              console.log(
                "Access token retrieved via popup:",
                response.accessToken
              );
              sessionStorage.setItem("authToken", response.accessToken);
              loadData(); // Load data immediately after getting the token
            })
            .catch((error) => {
              console.error("Failed to acquire token interactively:", error);
            });
        });
    }
  }, [isAuthenticated, instance, userAccount]);

  const loadData = async () => {
    setLoading(true);
    try {
      const applicationsResponse = await ApplicationService.getApplications();
      const usersResponse = await UserService.getUsers();
      console.log("Fetched applications:", applicationsResponse);
      console.log("Fetched users:", usersResponse);
      setApplications(applicationsResponse);
      setUsers(usersResponse);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  };

  const addApplication = async (newData) => {
    setLoading(true);
    try {
      const addedApplication = await ApplicationService.addApplication(newData);
      console.log("Added application:", addedApplication);
      setApplications([...applications, addedApplication]);
    } catch (error) {
      if (error.response) {
        // Check if the error response status is 404
        if (error.response.status === 404) {
          setError("Guacamole service is unavailable. Queueing the request.");
          setShowErrorModal(true);
        } else {
          setError("Failed to add application. Please try again later.");
          setShowErrorModal(true);
        }
      } else {
        // Handle errors without a response (network errors, etc.)
        setError("Failed to add application. Please try again later.");
        setShowErrorModal(true);
      }
      console.error("Failed to add application:", error);
    } finally {
      setLoading(false);
      loadData(); // Ensure the list is updated after the add operation
    }
  };

  const launchApplication = (application) => {
    console.log("Opening application with URI:", application.applicationUri);
    if (launchInNewTab) {
      window.open(application.applicationUri, "_blank");
    } else {
      if (
        !activeApps.some(
          (app) => app.applicationId === application.applicationId
        )
      ) {
        setActiveApps([...activeApps, application]);
      }
    }
  };

  const showUsers = () => {
    setCurrentView("users");
  };

  const showApplications = () => {
    setCurrentView("applications");
  };

  const deleteUser = async (user) => {
    try {
      await UserService.deleteUser(user.id);
      setUsers(users.filter((u) => u.id !== user.id));
    } catch (error) {
      console.error("Failed to delete user:", error);
    }
  };

  const deleteApplication = async (application) => {
    console.log("Deleting application:", application);
    try {
      await ApplicationService.deleteApplication(application.applicationId);
      setApplications(
        applications.filter(
          (a) => a.applicationId !== application.applicationId
        )
      );
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("You are not authorized to delete this application.");
      } else {
        setError("Failed to delete application. Please try again later.");
      }
      setShowErrorModal(true);
      console.error("Failed to delete application:", error);
    }
  };

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  const handleErrorModalHide = () => {
    setShowErrorModal(false);
    setError(null);
  };

  return (
    <div>
      {isAuthenticated ? (
        loading ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <h2>Loading data...</h2>
          </div>
        ) : (
          <>
            <Button
              icon="pi pi-bars"
              onClick={() => setVisible(true)}
              className="p-mb-5"
              style={{
                position: "fixed",
                left: 0,
                top: "30%",
                height: "20%",
                width: "25px",
                borderRadius: "0 20px 20px 0",
              }}
            />
            <CustomSidebar
              visible={visible}
              setVisible={setVisible}
              showApplications={showApplications}
              showUsers={showUsers}
            />
            <div
              style={{
                position: "fixed",
                top: "10px",
                right: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                checked={launchInNewTab}
                onChange={(e) => setLaunchInNewTab(e.target.checked)}
              />
              <label style={{ marginLeft: "8px" }}>Launch in New Tab</label>
            </div>
            {currentView === "applications" ? (
              <Applications
                applications={applications}
                launchApplication={launchApplication}
                addApplication={addApplication}
                deleteApplication={deleteApplication}
              />
            ) : (
              <Users users={users} deleteUser={deleteUser} />
            )}
            {activeApps.map((app, index) => (
              <Dialog
                key={index}
                visible={true}
                modal
                onHide={() => {
                  setActiveApps(activeApps.filter((_, i) => i !== index));
                }}
                header={`Guacamole - ${app.applicationName}`}
                style={{ width: "80vw", height: "80vh" }}
              >
                <GuacamoleConnection setAuthStatus={setGuacAuthStatus} />
              </Dialog>
            ))}
            <ErrorModal
              visible={showErrorModal}
              onHide={handleErrorModalHide}
              message={error}
            />
          </>
        )
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            textAlign: "center",
          }}
        >
          <h2>Please log in.</h2>
          <button
            onClick={handleLogin}
            style={{
              padding: "10px 20px",
              fontSize: "18px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              color: "#fff",
              backgroundColor: "#007BFF",
              marginTop: "20px",
            }}
          >
            Log In
          </button>
        </div>
      )}
    </div>
  );
};

export default App;
