import React, { useEffect, useRef } from "react";
import Guacamole from "guacamole-common-js";

const GuacamoleConnection = ({ setAuthStatus }) => {
  const displayElement = useRef(null);
  const tunnel = useRef(null);
  const client = useRef(null);
  const guacamoleServerUri = process.env.REACT_APP_GUACAMOLE_SERVER_URI;

  useEffect(() => {
    const handleConnect = () => {
      setAuthStatus(true);
      console.log("Connected to Guacamole server.");
    };

    const handleDisconnect = (reason) => {
      setAuthStatus(false);
      console.log(`Disconnected from Guacamole server. Reason: ${reason}`);
    };

    const handleError = (error) => {
      console.error(
        `Guacamole connection error: ${error.message || "Unknown error"}`
      );
    };

    if (guacamoleServerUri) {
      console.log(`Connecting to Guacamole server at ${guacamoleServerUri}`);

      tunnel.current = new Guacamole.WebSocketTunnel(guacamoleServerUri);
      client.current = new Guacamole.Client(tunnel.current);

      client.current.connect();

      const display = client.current.getDisplay().getElement();
      if (displayElement.current) {
        displayElement.current.appendChild(display);
      }

      tunnel.current.onerror = handleError;
      tunnel.current.onstatechange = (state) => {
        console.log(`Guacamole tunnel state changed: ${state}`);
        switch (state) {
          case Guacamole.Tunnel.State.OPEN:
            handleConnect();
            break;
          case Guacamole.Tunnel.State.CLOSED:
            handleDisconnect("Tunnel closed");
            break;
          case Guacamole.Tunnel.State.UNSTABLE:
            console.log("Connection to Guacamole server is unstable.");
            break;
          default:
            handleDisconnect("Unknown state change");
        }
      };
    } else {
      console.error("Guacamole server URI is not defined.");
    }

    return () => {
      console.log("Disconnecting from Guacamole server");
      client.current.disconnect();
    };
  }, [guacamoleServerUri, setAuthStatus]);

  return (
    <div ref={displayElement} style={{ width: "100%", height: "100%" }}></div>
  );
};

export default GuacamoleConnection;
