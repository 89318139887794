import React from "react";
import { Form, Field } from "react-final-form";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

const AddUserForm = ({ addUser, onHide }) => {
  const onSubmit = async (values) => {
    console.log("Form Values:", values);
    addUser(values);
    onHide(); // Hide form after submission
  };

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "First name is required";
    }
    if (!values.lastName) {
      errors.lastName = "Last name is required";
    }
    if (!values.email) {
      errors.email = "Email is required";
    }
    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className="p-fluid">
          <Field name="firstName">
            {({ input, meta }) => (
              <div className="p-field">
                <label htmlFor="firstName">First Name</label>
                <InputText
                  id="firstName"
                  {...input}
                  placeholder="Enter first name"
                />
                {meta.error && meta.touched && (
                  <small className="p-error">{meta.error}</small>
                )}
              </div>
            )}
          </Field>
          <Field name="lastName">
            {({ input, meta }) => (
              <div className="p-field">
                <label htmlFor="lastName">Last Name</label>
                <InputText
                  id="lastName"
                  {...input}
                  placeholder="Enter last name"
                />
                {meta.error && meta.touched && (
                  <small className="p-error">{meta.error}</small>
                )}
              </div>
            )}
          </Field>
          <Field name="telephone">
            {({ input, meta }) => (
              <div className="p-field">
                <label htmlFor="telephone">Telephone</label>
                <InputText
                  id="telephone"
                  {...input}
                  placeholder="Enter telephone number"
                />
                {meta.error && meta.touched && (
                  <small className="p-error">{meta.error}</small>
                )}
              </div>
            )}
          </Field>
          <Field name="email">
            {({ input, meta }) => (
              <div className="p-field">
                <label htmlFor="email">Email</label>
                <InputText
                  id="email"
                  {...input}
                  placeholder="Enter email address"
                />
                {meta.error && meta.touched && (
                  <small className="p-error">{meta.error}</small>
                )}
              </div>
            )}
          </Field>
          <Field name="company">
            {({ input, meta }) => (
              <div className="p-field">
                <label htmlFor="company">Company</label>
                <InputText
                  id="company"
                  {...input}
                  placeholder="Enter company name"
                />
                {meta.error && meta.touched && (
                  <small className="p-error">{meta.error}</small>
                )}
              </div>
            )}
          </Field>
          <Button
            type="submit"
            label="Submit"
            disabled={submitting || pristine}
            className="p-mt-2"
          />
        </form>
      )}
    />
  );
};

export default AddUserForm;
