import React from "react";
import { Form, Field } from "react-final-form";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

const AddApplicationForm = ({ addApplication, onHide }) => {
  const onSubmit = async (values) => {
    console.log("Form Values:", values);
    try {
      const application = await addApplication(values);
      console.log("Application created:", application);
      onHide(); // Hide form after submission
    } catch (error) {
      console.error("Failed to create application:", error);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.applicationName) {
      errors.applicationName = "Application name is required";
    }
    if (!values.applicationUri) {
      errors.applicationUri = "Application URI is required";
    }
    if (!values.applicationType) {
      errors.applicationType = "Application type is required";
    }
    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, form, submitting, pristine }) => (
        <form onSubmit={handleSubmit} className="p-fluid">
          <Field name="applicationName">
            {({ input, meta }) => (
              <div className="p-field">
                <label htmlFor="applicationName">Application Name</label>
                <InputText
                  id="applicationName"
                  {...input}
                  placeholder="Enter application name"
                />
                {meta.error && meta.touched && (
                  <small className="p-error">{meta.error}</small>
                )}
              </div>
            )}
          </Field>
          <Field name="applicationDescription">
            {({ input, meta }) => (
              <div className="p-field">
                <label htmlFor="applicationDescription">Description</label>
                <InputText
                  id="applicationDescription"
                  {...input}
                  placeholder="Enter description"
                />
                {meta.error && meta.touched && (
                  <small className="p-error">{meta.error}</small>
                )}
              </div>
            )}
          </Field>
          <Field
            name="applicationUri"
            validate={(value) => {
              const uriPattern = /^[a-zA-Z][a-zA-Z0-9+.-]*:\/\/[^\s]*$/;
              return uriPattern.test(value) ? undefined : "Invalid URI";
            }}
          >
            {({ input, meta }) => (
              <div className="p-field">
                <label htmlFor="applicationUri">URI</label>
                <InputText
                  id="applicationUri"
                  {...input}
                  placeholder="Enter URI"
                  onChange={(e) => {
                    input.onChange(e); // default handler
                    const protocol = e.target.value
                      .split("://")[0]
                      .toUpperCase();
                    if (["HTTPS", "RDP", "SSH", "VNC"].includes(protocol)) {
                      form.change("applicationType", protocol);
                    }
                  }}
                />
                {meta.error && meta.touched && (
                  <small className="p-error">{meta.error}</small>
                )}
              </div>
            )}
          </Field>
          <Field name="applicationType">
            {({ input, meta }) => (
              <div className="p-field">
                <label htmlFor="applicationType">Application Type</label>
                <Dropdown
                  id="applicationType"
                  {...input}
                  options={[
                    { label: "HTTPS", value: "HTTPS" },
                    { label: "RDP", value: "RDP" },
                    { label: "SSH", value: "SSH" },
                    { label: "VNC", value: "VNC" },
                  ]}
                  placeholder="Select an application type"
                />
                {meta.error && meta.touched && (
                  <small className="p-error">{meta.error}</small>
                )}
              </div>
            )}
          </Field>
          <Button
            type="submit"
            label="Submit"
            disabled={submitting || pristine}
            className="submit-button"
          />
        </form>
      )}
    />
  );
};

export default AddApplicationForm;
