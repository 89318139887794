import { jwtDecode } from "jwt-decode";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const fetchApi = async (endpoint, options = {}) => {
  const token = sessionStorage.getItem("authToken");
  console.log("Using token for API request:", token);
  if (!token) {
    console.error("No token available in sessionStorage.");
    throw new Error("Authorization token is missing!");
  }

  let decodedToken;
  try {
    decodedToken = jwtDecode(token);
    console.log("Decoded Token:", decodedToken);
  } catch (error) {
    console.error("Failed to decode token:", error);
    throw error;
  }

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    ...options.headers,
  };

  console.log("Making API request to:", endpoint);
  console.log("Request headers:", headers);

  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      ...options,
      headers,
    });
    console.log("Response Status:", response.status);

    if (!response.ok) {
      const errorText = await response.text();
      console.error("HTTP Error Response:", errorText);
      const error = new Error(
        `HTTP error! Status: ${response.status}, ${errorText}`
      );
      error.response = response;
      throw error;
    }

    const text = await response.text();
    if (!text) {
      console.log("Empty response body");
      return null; // or any other appropriate value for an empty response
    }

    try {
      return JSON.parse(text);
    } catch (jsonError) {
      console.error("Failed to parse JSON:", text);
      throw new SyntaxError(`Failed to parse JSON: ${text}`);
    }
  } catch (error) {
    console.error(`Error in fetchApi for ${endpoint}:`, error);
    throw error;
  }
};

export default fetchApi;
