import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import AddApplicationForm from "./AddApplicationForm";

const Applications = ({
  applications,
  launchApplication,
  addApplication,
  deleteApplication,
}) => {
  const [showAddApplicationForm, setShowAddApplicationForm] = useState(false);
  const [appList, setAppList] = useState(applications);

  useEffect(() => {
    setAppList(applications);
  }, [applications]);

  const handleAddApplicationClick = () => {
    setShowAddApplicationForm(true);
  };

  const onHide = () => {
    setShowAddApplicationForm(false);
  };

  const handleAddApplication = async (values) => {
    try {
      const newApplication = await addApplication(values);
      setAppList([...appList, newApplication]);
      onHide();
    } catch (error) {
      console.error("Failed to create application:", error);
    }
  };

  const footer = (
    <div style={{ textAlign: "right" }}>
      <Button
        label="Add Application"
        icon="pi pi-plus"
        onClick={handleAddApplicationClick}
        className="p-button-success"
      />
    </div>
  );

  return (
    <div>
      {Array.isArray(applications) && (
        <DataTable
          value={applications}
          footer={footer}
          className="applicationlist"
        >
          <Column field="applicationName" header="Name"></Column>
          <Column field="applicationDescription" header="Description"></Column>
          <Column field="applicationUri" header="URI"></Column>
          <Column
            field="status"
            header="Status"
            body={(data) => {
              let statusStyle = "";
              switch (data.status) {
                case "pending":
                  statusStyle = "status-pending";
                  break;
                case "online":
                  statusStyle = "status-online";
                  break;
                case "rejected":
                  statusStyle = "status-rejected";
                  break;
                default:
                  statusStyle = "status-default";
              }
              return <span className={statusStyle}>{data.status}</span>;
            }}
          />
          <Column
            header="Launch"
            body={(data) => (
              <Button
                className="launch-button"
                icon="pi pi-external-link"
                label={data.applicationType}
                onClick={() => launchApplication(data)}
              />
            )}
          />
          <Column
            header="Delete"
            body={(data) => (
              <Button
                className="delete-button custom-delete-button"
                icon="pi pi-trash"
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you wish to delete this application?"
                    )
                  ) {
                    deleteApplication(data);
                  }
                }}
              />
            )}
          />
        </DataTable>
      )}
      <Dialog
        className="fixed-size-modal"
        header="Add Application"
        visible={showAddApplicationForm}
        onHide={onHide}
        modal
      >
        <AddApplicationForm
          addApplication={handleAddApplication}
          onHide={onHide}
        />
      </Dialog>
    </div>
  );
};

export default Applications;
