import fetchApi from "./apiClient";

const getApplications = () => fetchApi("/applications");
const addApplication = (newData) =>
  fetchApi("/applications", { method: "POST", body: JSON.stringify(newData) });
const deleteApplication = (applicationId) =>
  fetchApi(`/applications/${applicationId}`, { method: "DELETE" });

const ApplicationService = {
  getApplications,
  addApplication,
  deleteApplication,
};

export default ApplicationService;
