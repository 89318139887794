import React, { useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import AddUserForm from "./AddUserForm";

const Users = ({ users, addUser, deleteUser }) => {
  const [showAddUserForm, setShowAddUserForm] = useState(false);

  const handleAddUserClick = () => {
    setShowAddUserForm(true);
  };

  const hideDialog = () => {
    setShowAddUserForm(false);
  };

  const footer = (
    <div style={{ textAlign: "right" }}>
      <Button
        label="Add User"
        icon="pi pi-plus"
        onClick={handleAddUserClick}
        className="p-button-success"
      />
    </div>
  );

  return (
    <div>
      {Array.isArray(users) && (
        <DataTable value={users} footer={footer} className="userlist">
          <Column field="firstName" header="First Name"></Column>
          <Column field="lastName" header="Last Name"></Column>
          <Column field="telephone" header="Telephone"></Column>
          <Column field="email" header="Email"></Column>
          <Column field="company" header="Company"></Column>
          <Column
            header="Delete"
            body={(data) => (
              <Button
                className="delete-button custom-delete-button"
                icon="pi pi-trash"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you wish to delete this user?")
                  ) {
                    deleteUser(data);
                  }
                }}
              />
            )}
          />
        </DataTable>
      )}
      <Dialog
        className="fixed-size-modal"
        header="Add User"
        visible={showAddUserForm}
        onHide={hideDialog}
        modal
      >
        <AddUserForm addUser={addUser} />
      </Dialog>
    </div>
  );
};

export default Users;
