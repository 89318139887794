// Sidebar.js
import React from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";

const CustomSidebar = ({
  visible,
  setVisible,
  showApplications,
  showUsers,
}) => (
  <Sidebar
    visible={visible}
    onHide={() => setVisible(false)}
    position="left"
    className={`sidebar ${visible ? "sidebar-visible" : "sidebar-hidden"}`}
    modal={false} // Disable modal overlay if desired
  >
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px",
      }}
    >
      <Button
        label="Dashboard"
        icon="pi pi-fw pi-home"
        className="p-mr-2 p-mb-5"
        style={{ width: "100%" }}
        onClick={showApplications}
      />
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px",
      }}
    >
      <Button
        label="Settings"
        icon="pi pi-fw pi-cog"
        className="p-mr-2 p-mb-5"
        style={{ width: "100%" }}
      />
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px",
      }}
    >
      <Button
        label="Users"
        icon="pi pi-fw pi-users"
        className="p-mr-2 p-mb-5"
        style={{ width: "100%" }}
        onClick={showUsers}
      />
    </div>
  </Sidebar>
);

export default CustomSidebar;
