import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const ErrorModal = ({ visible, onHide, message }) => {
  return (
    <Dialog header="Error" visible={visible} onHide={onHide} modal>
      <p>{message}</p>
      <Button label="Close" onClick={onHide} />
    </Dialog>
  );
};

export default ErrorModal;
